/* eslint-disable react/prop-types */
import { createContext, useState, useEffect, useContext } from 'react';
import { useGoogleFonts } from './useGoogleFonts';

const FontsContext = createContext();

export function FontsProvider({ children }) {
  const fonts = useProvideFonts();
  return (
    <FontsContext.Provider value={fonts}>{children}</FontsContext.Provider>
  );
}

function useProvideFonts() {
  const [fonts, setFonts] = useState(null);
  const [googleFonts, setGoogleFonts] = useState(null);
  const [showItems, setShowItems] = useState(50);

  const [filteredFonts, setFilteredFonts] = useState([]);

  const { data: googleFontsData } = useGoogleFonts();

  useEffect(() => {
    if (googleFontsData) {
      setGoogleFonts(
        Array.from(googleFontsData.items)
          // .slice(0, showItems)
          .map((font) => {
            var newFont = {};
            newFont.font_family = font.family;
            newFont.font_family_alias = font.family;
            newFont.category = font.category;
            newFont.url = (font.files.regular + '').replace(
              /^http:\/\//i,
              'https://'
            );
            newFont.selected = true;
            return newFont;
          })
      );
    }
  }, [googleFontsData]);

  // useEffect(() => {
  //   if (apiData) {
  //     setGoogleFonts(
  //       Array.from(apiData.items)
  //         .slice(0, showItems)
  //         .map((font) => {
  //           var newFont = {};
  //           newFont.fontFamily = font.family;
  //           newFont.fontFamilyAlias = font.family;
  //           newFont.category = font.category;
  //           newFont.url = font.files.regular;
  //           newFont.selected = true;
  //           return newFont;
  //         })
  //     );
  //   }
  // }, [apiData, showItems]);

  // useEffect(() => {
  //   setLoadingGoogle(true);
  //   fetch(
  //     'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAqb_sa4DfkMRpSHuoMyyh3rHSNqSN44d0&sort=popularity'
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('response.json()', data);
  //       // * Sort fonts before updating state
  //       setGoogleFonts(
  //         Array.from(data.items)
  //           .slice(0, showItems)
  //           .map((font) => {
  //             var newFont = {};
  //             newFont.fontFamily = font.family;
  //             newFont.fontFamilyAlias = font.family;
  //             newFont.category = font.category;
  //             newFont.url = font.files.regular;
  //             newFont.selected = true;
  //             return newFont;
  //           })
  //       );
  //       setLoadingGoogle(false);
  //     })
  //     .catch((error) => {
  //       setServerError(error);
  //       setLoadingGoogle(false);
  //     });
  // }, [showItems]);

  const selectAllFonts = (selected) => {
    const newFonts = fonts.map((font) => {
      return { ...font, selected };
    });
    setFonts(newFonts);
  };

  const selectAllGoogleFonts = (selected) => {
    const newFonts = googleFonts.map((font) => {
      return { ...font, selected };
    });
    setGoogleFonts(newFonts);
  };

  const updateFont = (id, selected) => {
    const newFonts = fonts.map((font) => {
      if (font.id === id) {
        return { ...font, selected };
      }
      return { ...font };
    });
    setFonts(newFonts);
  };

  const updateGoogleFont = (fontFamily, selected) => {
    const newFonts = googleFonts.map((font) => {
      if (font.fontFamily === fontFamily) {
        return { ...font, selected };
      }
      return { ...font };
    });
    setGoogleFonts(newFonts);
  };

  const addFontToFilter = (id, isToAdd) => {
    if (isToAdd) {
      setFilteredFonts(filteredFonts.push(id));
    } else {
      setFilteredFonts(filteredFonts.pop(id));
    }
  };

  return {
    fonts,
    setFonts,
    googleFonts,
    // loadingGoogle: isLoading,
    // serverError: googleError,
    setGoogleFonts,
    showItems,
    setShowItems,
    selectAllFonts,
    selectAllGoogleFonts,
    updateFont,
    updateGoogleFont,
    addFontToFilter
  };
}

export const useFontsState = () => {
  const fonts = useContext(FontsContext);

  if (fonts === undefined) {
    throw new Error('useFontsState must be used within FontsProvider');
  }

  return fonts;
};
