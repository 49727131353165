import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: {
      50: '#fff6da',
      100: '#ffe3ad',
      200: '#ffd17d',
      300: '#ffbf4b',
      400: '#ffac1a',
      500: '#e69300',
      600: '#b37200',
      700: '#815200',
      800: '#4e3000',
      900: '#1f0f00'
    },
    darkpastelgreen: {
      50: '#e3fce8',
      100: '#c0efc8',
      200: '#9ae3a6',
      300: '#74d784',
      400: '#4ecb62',
      500: '#34b148',
      600: '#268a37',
      700: '#196326',
      800: '#0a3c15',
      900: '#001600'
    },
    darkblue: {
      50: '#F2F2F3',
      100: '#D9DBDD',
      200: '#C1C3C8',
      300: '#A9ACB2',
      400: '#90949D',
      500: '#787D87',
      600: '#60646C',
      700: '#484B51',
      800: '#303236',
      900: '#18191B'
    },
    lightgray: {
      50: '#F7FAFC',
      100: '#F7FAFC',
      200: '#F7FAFC',
      300: '#C4C4C4',
      400: '#C4C4C4',
      500: '#C4C4C4',
      600: '#808080',
      700: '#808080',
      800: '#808080',
      900: '#808080'
    }
  }
  // components: {
  //   Button: {
  //     // 1. We can update the base styles
  //     baseStyle: {
  //       fontWeight: "bold", // Normally, it is "semibold"
  //     },
  //     // 2. We can add a new button size or extend existing
  //     sizes: {
  //       xl: {
  //         h: "56px",
  //         fontSize: "lg",
  //         px: "32px",
  //       },
  //     },
  //     // 3. We can add a new visual variant
  //     variants: {
  //       "with-shadow": {
  //         bg: "red.400",
  //         boxShadow: "0 0 2px 2px #efdfde",
  //       },
  //       // 4. We can override existing variants
  //       solid: (props) => ({
  //         bg: props.colorMode === "dark" ? "red.300" : "red.500",
  //       }),
  //     },
  //   },
  // },
});
export default theme;
