export const SITE_DOMAIN =
  process.env.NODE_ENV === 'production'
    ? 'https://app.fontvisual.com'
    : 'http://localhost:3000';

export const userStatus = new Set([
  'downgrade_free',
  'free',
  'trial',
  'trial_ended',
  'subscription',
  'subscription_cancelled',
  'subscription_cancelled_end'
]);

export const BYTES_PER_GB = 1073741824;
export const ONE_GB = 1073741824;
export const MAX_BYTES_PER_IMAGE = 5242880; //5MB
export const MAX_BYTES_PER_FONT = 104857600; //100MB

export const STARTER_MONTHLY_PLAN_ID = 736723;
export const STARTER_YEARLY_PLAN_ID = 736724;
export const PLUS_MONTHLY_PLAN_ID = 736725; //16736
export const PLUS_YEARLY_PLAN_ID = 736726;

export const TRIAL_DURATION = 7;

export const LOGSNAG_API_TOKEN = '14280fae8039c6b87d6012400cec6f4f';

export const EXAMPLE_PATH = 'blog-starter';
export const CMS_NAME = 'Markdown';
export const HOME_OG_IMAGE_URL = 'https://fontvisual.com/fontvisual.png';
