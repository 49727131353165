import { createClient } from '@supabase/supabase-js';
import { TRIAL_DURATION } from './constants';

export const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
);

export const getActiveProductsWithPrices = async () => {
  const { data, error } = await supabase
    .from('products')
    .select('*, prices(*)')
    .eq('active', true)
    .eq('prices.active', true)
    .order('metadata->index')
    .order('unit_amount', { foreignTable: 'prices' });

  if (error) {
    // console.log(error.message);
    throw error;
  }

  return data || [];
};

export const updateUserName = async (user, name) => {
  await supabase
    .from('users')
    .update({
      full_name: name
    })
    .eq('id', user.id);
};

export const updateUserPlan = async (user, planId) => {
  var today = new Date();
  var trialEndDate = new Date();
  trialEndDate.setDate(today.getDate() + TRIAL_DURATION);
  await supabase
    .from('users')
    .update({
      trial_start: today,
      trial_end: trialEndDate,
      plan_id: planId
    })
    .eq('id', user.id);
};

export const createOrRetrieveCustomer = async ({ userId, planId, email }) => {
  const { data, error } = await supabase
    .from('users')
    .select('*')
    .eq('id', userId)
    .single();
  if (error) {
    // No customer record found, let's create one.

    // const customerData = {
    //   metadata: {
    //     supabaseUUID: uuid
    //   }
    // };
    // if (email) customerData.email = email;
    // const customer = await stripe.customers.create(customerData);

    // Now insert the customer ID into our Supabase mapping table.
    var today = new Date();
    var trialEndDate = new Date();
    trialEndDate.setDate(today.getDate() + TRIAL_DURATION);
    const { data: customer, error: supabaseError } = await supabase
      .from('users')
      .insert([
        {
          id: userId,
          trial_start: today,
          trial_end: trialEndDate,
          plan_id: planId,
          email
        }
      ]);
    if (supabaseError) throw supabaseError;

    // console.log(`New customer created and inserted for ${userId}.`);
    return customer.id;
  }
  if (data) return data;
};

export const getFontsSizeTotal = async (user) => {
  const { data, error } = await supabase.rpc('get_user_font_size_total', {
    userid: user.id
  });
  if (error) {
    // console.log(error.message);
    throw error;
  }

  // console.log('get_user_font_size_total data ', data);

  return data;
};
