import { useRouter } from 'next/router';
import { useEffect, useState, createContext, useContext } from 'react';
import { supabase } from '@/utils/supabase-client';
import { useMutation, useQueryClient } from 'react-query';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [userLoaded, setUserLoaded] = useState(false);
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [trial, setTrial] = useState(null);
  const [recoveryToken, setRecoveryToken] = useState(null);
  const router = useRouter();

  useEffect(() => {
    // console.log('useEffect UserContextProvider');
    const session = supabase.auth.session();
    setSession(session);
    setUser(session?.user ?? null);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        handleAuthChange(event, session);
        setSession(session);
        setUser(session?.user ?? null);
      }
    );

    return () => {
      authListener.unsubscribe();
    };
  }, []);

  async function handleAuthChange(event, session) {
    // console.log('handleAuthChange');
    /* sets and removes the Supabase cookie */
    await fetch('/api/auth', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({ event, session })
    });
  }

  const getUserDetails = () => supabase.from('users').select('*').single();
  const getSubscription = () =>
    supabase
      .from('subscriptions')
      // .select('*, prices(*, products(*))')
      .select('*, products(*)')
      .in('status', ['trialing', 'active'])
      .maybeSingle();
  const getTrial = () =>
    supabase.from('customers').select('*, products(*)').single();

  useEffect(() => {
    if (user) {
      Promise.allSettled([
        getUserDetails()
        // getSubscription()
        // getTrial()
      ]).then((results) => {
        setUserDetails(results[0].value.data);
        // setSubscription(results[1].value.data);
        // setTrial(results[2].value.data);
        setUserLoaded(true);
      });
    }
  }, [user]);

  const queryClient = useQueryClient();
  const value = {
    session,
    getSession: () => supabase.auth.session(),
    user, //: user && user.status != 'deleted' ? user : null,
    userDetails,
    userLoaded,
    // subscription,
    // trial,
    signIn: (options) => supabase.auth.signIn(options),
    signUp: (data, options) => supabase.auth.signUp(data, options),
    update: (options) => {
      // console.log('options', options);
      return supabase.auth.update(options);
    },
    signOut: () => {
      setUserDetails(null);
      // setSubscription(null);
      queryClient.removeQueries();
      return supabase.auth.signOut();
    },
    resetPassword: (email) => supabase.auth.api.resetPasswordForEmail(email),
    deleteAccount: () => {
      return supabase.from('users').update({ status: 'deleted' });
    }
  };
  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
