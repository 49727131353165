import { ThemeProvider, CSSReset } from '@chakra-ui/react';
import { FontsProvider } from '../hooks/fonts';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../styles/global.css';
import customTheme from '../styles/theme';
import { UserContextProvider } from '../utils/useUser';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      // refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

export default function MyApp({ Component, pageProps }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  const RenderLayout = () => {
    return getLayout(<Component {...pageProps} />);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <FontsProvider>
            <RenderLayout />
          </FontsProvider>
        </UserContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
