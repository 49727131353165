import { useQuery } from 'react-query';

const fetchGoogleFonts = async () => {
  const data = (
    await fetch(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAqb_sa4DfkMRpSHuoMyyh3rHSNqSN44d0&sort=popularity'
    )
  ).json();

  // const data = await res.json();
  // console.log('fetchGoogleFonts', data);

  // const googleFonts = Array.from(data.items)
  //   .slice(0, limit)
  //   .map((font) => {
  //     var newFont = {};
  //     newFont.fontFamily = font.family;
  //     newFont.fontFamilyAlias = font.family;
  //     newFont.category = font.category;
  //     newFont.url = font.files.regular.replace(/^http:\/\//i, 'https://');
  //     newFont.selected = true;
  //     return newFont;
  //   });

  return data;
};

const useGoogleFonts = () => {
  // return useQuery(['googleFonts', limit], () => fetchGoogleFonts(limit));
  return useQuery('googleFonts', () => fetchGoogleFonts(), {
    enabled: false
  });

  // const googleFonts = Array.from(data.items)
  // .slice(0, limit)
  // .map((font) => {
  //   var newFont = {};
  //   newFont.fontFamily = font.family;
  //   newFont.fontFamilyAlias = font.family;
  //   newFont.category = font.category;
  //   newFont.url = font.files.regular.replace(/^http:\/\//i, 'https://');
  //   newFont.selected = true;
  //   return newFont;
  // });

  // return googleFonts;
};

export { useGoogleFonts, fetchGoogleFonts };
